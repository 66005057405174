@mixin media-desktop {
  @media screen and (max-width: $breakpoint-desktop) {
    @content;
  }

}

@mixin media-tablet {
  @media screen and (max-width: $breakpoint-tablet) {
    @content;
  }
}

@mixin media-mobile {
  @media screen and (max-width: $breakpoint-mobile) {
    @content;
  }
}