
          @import "src/assets/styles/variables.module.scss";
          @import "src/assets/styles/media.scss";
        
.block {
  height: 800px;
  background-color: black;
  color: white;
  padding: 0px 150px;
  position: relative;
  overflow: hidden;

  &__container {
    padding: 156px 0px;
    position: relative;
    max-width: $container;
    width: 100%;
    height: 100%;
    margin: 0 auto;
  }

  &__content {
    position: relative;
    z-index: 2;
    text-align: left;
  }

  &__heading {
    font-size: 60px;
    line-height: 64px;
    font-weight: 500;
    text-shadow: 0px 0px 20px rgba(0, 0, 0, 0.45);
    max-width: 400px;
    word-spacing: 100vw;
  }

  &__text {
    text-align: left;
    margin-top: 24px;
    font-size: 16px;
    font-weight: 400;
    max-width: 410px;
    text-shadow: 0px 0px 20px rgba(0, 0, 0, 0.45);
  }

  &__button {
    height: 60px;
    background-color: white;
    padding: 8px 37px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $black;
    font-size: 18px;
    font-weight: 500;
    line-height: normal;
    text-align: center;
    border-radius: 76px;
    margin-top: 40px;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
    width: max-content;
    cursor: pointer;
    border: 1px solid white;

    &--shown {
      opacity: 1;
    }
  }

  &__videoContainer {
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    display: flex;
    justify-content: flex-end;
  }

  &__video {
    object-fit: cover;
    width: auto;
    height: 100%;
    transform: translateX(25%);
  }

  &__cupContainer {
    display: flex;
    flex-direction: column;
    gap: 12px;
    margin-top: 36px;
  }

  &__icon {
    width: 20px;
    height: 20px;
    color: $yellow;
  }

  &__lottery {
    max-width: 382px;
    color: $yellow;
    font-family:  "Poppins", sans-serif;
    font-size: 16px;
    font-weight: 500;
    line-height: normal;
  }
}

@include media-tablet {
  .block {
    padding: 0px 68px;
    height: 488px;

    &__container {
      padding: 74px 0;
    }

    &__br {
      display: none;
    }

    &__heading {
      font-size: 40px;
      font-weight: 500;
      text-shadow: 0px 0px 20px rgba(0, 0, 0, 0.45);
      line-height: normal;
      max-width: 580px;
      word-spacing: normal;
    }

    &__button {
      height: 48px;
      font-size: 16px;
      padding: 8px 20px;
      width: 150px;
    }

    &__cupContainer {
      margin-top: 40px;
      flex-direction: row;
      align-items: center;
    }

    &__lottery {
      font-size: 14px;
    }
  }
}

@include media-mobile {
  .block {
    height: auto;
    background-color: $black;
    padding: 0;

    &__container {
      padding: 0;
      display: flex;
      flex-direction: column-reverse;
    }

    &__content {
      padding: 20px 15px 20px 15px;
      margin: 0 auto;


      // WITH BUTTON
      // @media screen and (max-width: 768px) {
      //   height: 258px;
      // }

      // @media screen and (max-width: 450px) {
      //   height: 260px;
      // }

      // @media screen and (max-width: 422px) {
      //   height: 280px;
      // }

      // @media screen and (max-width: 360px) {
      //   height: 280px;
      // }

      // @media screen and (max-width: 346px) {
      //   height: 300px;
      // }

      // @media screen and (max-width: 306px) {
      //   height: 320px;
      // }


      // WITHOUT BUTTON
      @media screen and (max-width: 768px) {
        height: 318px;
      }

      @media screen and (max-width: 450px) {
        height: 329px;
      }

      @media screen and (max-width: 422px) {
        height: 329px;
      }

      @media screen and (max-width: 360px) {
        height: 329px;
      }

      @media screen and (max-width: 346px) {
        height: 378px;
      }

      @media screen and (max-width: 306px) {
        height: 418px;
      }

    }

    &__videoContainer {
      position: relative;
    }

    &__video {
      transform: none;
    }

    &__heading {
      text-align: center;
      font-size: 24px;
      max-width: 330px;
      margin: 0 auto;
      word-spacing: normal;
    }

    &__text {
      text-align: center;
      color: $main_grey;
      margin-top: 15px;
    }

    &__button {
      margin: 30px auto 0 auto;
    }

    &__cupContainer {
      margin-top: 30px;
    }
  }
}
