
          @import "src/assets/styles/variables.module.scss";
          @import "src/assets/styles/media.scss";
        
.content {
  margin: 0 auto;
  padding: 60px;
  max-width: 800px;
  color: $grey_dark;

  & h1 {
    font-size: 32px;
    line-height: 40px;
    margin-bottom: 20px;
    font-weight: 700;
    color: $secondary_black;
  }

  & p {
    margin-bottom: 20px;
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    color: $grey_dark;

    a {
      color: $grey_dark;
      text-decoration: underline;
    }
  }

  & h2 {
    margin-top: 60px;
    margin-bottom: 20px;
    font-size: 24px;
    font-weight: 600;
    line-height: 24px;
    color: $secondary_black;
  }

  & h3 {
    margin-bottom: 20px;
    font-size: 24px;
    font-weight: 600;
    line-height: 24px;
    color: $secondary_black;
  }

  & ol {
    padding: 0;
    padding-left: 10px;
  }

  & ol li {
    list-style: none;
    margin: 20px 0;

    ul {
      li {
        list-style-type: disc;
      }
    }

    p {
      margin-bottom: 0;

      &:first-child {
        margin-bottom: 8px;
      }
    }
  }


}

@include media-tablet {
  .content {
    padding: 40px;
  }
}

@include media-mobile {
  .content {
    padding: 20px;
  }
}
