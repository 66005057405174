
          @import "src/assets/styles/variables.module.scss";
          @import "src/assets/styles/media.scss";
        
.block {
  margin: 110px 0;

  &__container {
    max-width: 786px;
    margin: 0 auto;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  &__heading {
    text-align: center;
    font-size: 64px;
    font-weight: 700;
    color: $black;
  }

  &__description {
    margin-top: 8px;
    text-align: center;
    color: $main_grey;
    font-size: 16px;
    font-weight: 400;
  }

  &__form {
    margin-top: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
  }

  &__col {
    display: flex;
    flex-direction: column;
    gap: 24px;
    height: auto;
    width: 100%;
  }

  &__row {
    display: flex;
    flex-direction: column;
    gap: 10px;
    height: 100%;
    width: 100%;
  }

  &__grid {
    display: flex;
    gap: 20px;
    width: 100%;
    margin-bottom: 60px;
  }

  &__input {
    outline: none;
    border: 1px solid $grey_border;
    background-color: white;
    color: $black;
    padding: 12px;
    border-radius: 5px;
    height: 48px;
    width: 100%;

    &[name='message'] {
      height: 100%;
      display: block;
      resize: none;
    }

    /* width */
    &::-webkit-scrollbar {
      width: 10px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      background: white;
      border-radius: 100px;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: #C8C8C8;
      border-radius: 26px;
      border: 3px solid white;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: #C8C8C8;
    }

    &::placeholder {
      font-size: 16px;
      font-weight: 400;
      line-height: 150%;
      color: $main_grey;
    }

    &--invalid {
      border-color: #f40c0c;
      background-color: #ee39390d;
    }
  }

  &__textareaContainer {
    width: 100%;
    height: 100%;
    position: relative;
  }

  &__symbols {
    right: 5px;
    bottom: -20px;
    color: $main_grey;
    font-size: 14px;
  }

  &__label {
    font-size: 14px;
    font-weight: 400;
    line-height: 130%;
    color: $black;
  }

  &__button {
    min-width: 224px;
  }

  &__flex {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

@include media-tablet {
  .block {
    padding: 0 34px;
    margin: 110px 0 80px 0;

    &__heading {
      font-size: 36px;
    }

    &__form {
      margin-top: 40px;
    }

    &__grid {
      gap: 30px;
    }

    &__button {
      min-width: 160px;
    }
  }
}

@include media-mobile {
  .block {
    padding: 0 15px;
    margin: 80px 0;

    &__col {
      height: 100%;
    }

    &__grid {
      flex-direction: column;
      gap: 24px;
      margin-bottom: 0;
    }

    &__input {
      height: 48px;

      &[name='message'] {
        height: 144px;
        min-height: 144px;
        display: block;
        margin-bottom: 40px;
      }
    }

    &__symbols {
      bottom: 20px;
      right: 5px;
    }
  }
}
