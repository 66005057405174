
          @import "src/assets/styles/variables.module.scss";
          @import "src/assets/styles/media.scss";
        
.pagination {
  display: flex;
  align-items: center;
  justify-content: space-between;

  ul {
    padding: 0;
  }

  &__info {
    font-size: 20px;
    font-weight: 400;
    color: $main_grey;
    user-select: none;
  }

  &__paginateContainer {
    border: 1px solid rgba(255, 255, 255, 0.05);
    padding: 4px 11px;
    height: 50px;
    width: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: black;
  }

  &__container {
    display: flex;
    align-items: center;
    padding: 0;
  }

  &__page {
    text-decoration: none;
    list-style: none;
    white-space: nowrap;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    user-select: none;
    font-size: 16px;
    text-decoration: none;
    width: 50px;
    height: 50px;
    color: $main_grey;
    font-weight: 500;

    a {
      display: flex;
      align-items: center;
      width: 30px;
    }
  }

  &__pageLink {
    text-decoration: none;
    list-style: none;
    width: max-content;
    height: 50px;
    width: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border-radius: 4px;
    user-select: none;
    font-size: 16px;
    font-weight: 400;
    color: $main_grey;

    a {
      width: 50px;
    }
  }

  &__active {
    @extend .pagination__page;

    cursor: default;
    font-weight: 700;
    text-decoration: none;
    background-color: black;
    color: white;

    a {
      color: white;
      width: 50px;
      height: 50px;
    }
  }

  &__prev,
  &__next,
  &__break {
    @extend .pagination__page;
    list-style: none;
    text-decoration: none;
    width: 50px;
    height: 50px;

    a {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  &__next {
    width: 50px;

    a {
      width: 50px;
    }
  }

  &__prev {
    width: 50px;

    a {
      width: 50px;
    }
  }

  &__disabled {
    @extend .pagination__page;

    color: $main_grey;
    cursor: default;
    pointer-events: none;
  }

  &__prevArrow {
    width: 16px;
    height: 16px;
    transform: rotate(0deg);
  }

  &__nextArrow {
    width: 16px;
    height: 16px;
    transform: rotate(180deg);
  }

  &__control {
    display: flex;
    align-items: center;
    width: auto;
    gap: 12px;
  }
}

@include media-tablet {
  .pagination {
    gap: 19px;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &__info {
      font-size: 20px;
      margin-left: auto;
    }

    &__container {
      padding: 0;
      width: 100%;
    }

    &__page {
      font-size: 16px;
    }
  }
}

@include media-mobile {
  .pagination {
    &__info {
      font-size: 16px;
    }
  }
}
