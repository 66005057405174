$black: #1C1B1B;
$secondary_black: #2D3436;
$tertiary_black: #201f1f;
$white: #F7EBDF;
$main_grey: #7B7B7B;
$grey_border: #c8c8c8;
$secondary_grey: #cbd5e1;
$grey_dark: #5C5C5C;
$cream: #ffdbb9;
$tertiary_grey: #EBEBEB;
$purple: #995BA7;
$yellow: #F7D41A;

$container: 1440px;

$breakpoint-desktop: 1439px;
$breakpoint-tablet: 1023px;
$breakpoint-mobile: 767px;