
          @import "src/assets/styles/variables.module.scss";
          @import "src/assets/styles/media.scss";
        
@import './elements.scss';

#root {
  max-width: 100vw;
  isolation: isolate;
}

html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: 'Lexend', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: $white;
  line-height: normal;
  -webkit-font-smoothing: antialiased;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

* {
  margin: 0;
}

img,
picture,
video,
canvas,
svg {
  display: block;
  max-width: 100%;
}

input,
button,
textarea,
select {
  font: inherit;
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
  overflow-wrap: break-word;
}

a {
  text-decoration: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.grecaptcha-badge { visibility: hidden; }