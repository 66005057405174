
          @import "src/assets/styles/variables.module.scss";
          @import "src/assets/styles/media.scss";
        
.item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 0;

  &:not(:last-child) {
    border-bottom: 1px solid $tertiary_grey;
  }

  &__block {
    display: flex;
    align-items: center;
    gap: 42px;
  }

  &__index {
    font-size: 18px;
    font-weight: 400;
    color: $main_grey;
    width: 10px;
  }

  &__user {
    display: flex;
    align-items: center;
    gap: 12px;
  }

  &__avatarWrapper {
    width: 45px;
    height: 45px;
    position: relative;
  }

  &__avatarContainer {
    width: 45px;
    height: 45px;
    border-radius: 50%;
    position: relative;
    overflow: hidden;
    background-color: $white;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__avatar {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }

  &__userIcon {
    width: 20px;
    height: 20px;
  }

  &__country {
    position: absolute;
    width: 15px;
    height: 15px;
    bottom: 0;
    right: 0;
    border-radius: 50%;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__name {
    font-size: 18px;
    font-weight: 400;
    color: $black;
  }

  &__statusContainer {
    display: flex;
    align-items: center;
    gap: 4px;
    color: $purple;
  }

  &__status {
    font-size: 14px;
    font-weight: 400;
  }

  &__scoreContainer {
    display: flex;
    align-items: center;
    gap: 8px;
    color: $black;
  }

  &__score {
    font-size: 18px;
    font-weight: 500;
  }

  &__iconContainer {
    width: 16px;
    height: 16px;
  }
}

@include media-mobile {
  .item {
    &__score {
      font-size: 16px;
    }

    &__block {
      gap: 10px;
    }
  }
}
