
          @import "src/assets/styles/variables.module.scss";
          @import "src/assets/styles/media.scss";
        
.block {
  padding: 0 150px;
  margin: 110px 0;

  &__container {
    margin: 0 auto;
    width: 100%;
    background-color: white;
    border-radius: 8px;
    max-width: 780px;
    min-height: 1030px;
    padding: 40px 60px;
    display: flex;
    flex-direction: column;
    gap: 23px;
  }

  &__heading {
    font-size: 60px;
    font-weight: 700;
    text-align: center;
    color: $black;
  }

  &__empty {
    text-align: center;
    font-size: 18px;
    font-weight: 500;
    color: $black
  }

  &__pagination {
    margin-top: auto;
  }

  &__loader {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
  }
}

@include media-tablet {
  .block {
    padding: 0 34px;
    margin: 110px 0;

    &__heading {
      font-size: 36px;
    }

    &__container {
      padding: 40px 20px;
    }
  }
}

@include media-mobile {
  .block {
    padding: 0;
    margin: 80px 0;

    &__container {
      padding: 20px 15px;
    }
  }
}
