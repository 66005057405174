
          @import "src/assets/styles/variables.module.scss";
          @import "src/assets/styles/media.scss";
        
.block {
  padding: 0px 150px;
  margin: 110px 0;

  &__container {
    background-color: $cream;
    overflow: hidden;
    position: relative;
    padding: 60px;
    max-width: $container;
    margin: 0 auto;
    color: $black;
    z-index: 2;
  }

  &__content {
    position: relative;
    z-index: 2;
  }

  &__imgContainer {
    position: absolute;
    top: 0;
    right: 0;
    height: 516px;
    width: 805px;
    z-index: 1;
    transform: translate(5%, 7%);
    display: flex;
    align-items: center;
  }

  &__text {
    margin: 40px 0;
    font-size: 36px;
    font-weight: 500;
    line-height: 140%;
    max-width: 930px;
  }

  &__button {
    color: #fff;
  }
}

@include media-tablet {
  .block {
    padding: 0px 34px;
    margin: 110px 0;

    &__container {
      padding: 40px;
    }

    &__text {
      font-size: 32px;
    }

    &__imgContainer {
      transform: translate(34%, -17%);
    }
  }
}

@include media-mobile {
  .block {
    padding: 0px 15px;
    margin: 80px 0;

    &__container {
      padding: 30px 20px;
    }

    &__text {
      font-size: 20px;
      margin: 20px 0 40px 0;
    }

    &__imgContainer {
      transform: translate(57%, -18%);
    }
  }
}
