.button {
  height: 60px;
  background-color: white;
  padding: 8px 37px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: $black;
  font-size: 18px;
  font-weight: 500;
  line-height: normal;
  text-align: center;
  border-radius: 76px;
  min-width: 180px;
  cursor: pointer;
  border: 1px solid white;

  &--black {
    background-color: $black;
    color: white;
    border-color: $black;

    &:disabled {
      opacity: 0.5;
    }
  }
}

@include media-tablet {
  .button {
    height: 48px;
    font-size: 16px;
  }
}
