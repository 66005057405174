
          @import "src/assets/styles/variables.module.scss";
          @import "src/assets/styles/media.scss";
        
.block {
  padding: 0px 150px;
  margin: 110px 0;

  &__container {
    width: 100%;
    max-width: $container;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    gap: 60px;
  }

  &__item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 60px;
    width: 100%;
    max-width: 1140px;
    margin: 0 auto;

    &--reverse {
      flex-direction: row-reverse;
    }
  }

  &__itemContent {
    max-width: 540px;
    text-align: left;
  }

  &__itemHeading {
    font-size: 48px;
    font-weight: 700;
    line-height: normal;
    max-width: 530px;
    color: $secondary_black;
  }

  &__itemText {
    margin-top: 20px;
    line-height: 160%;
    font-weight: 400;
    font-size: 16px;
    color: $grey_dark;
  }

  &__itemImgContainer {
    height: 640px;
    min-width: 540px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__itemImg {
    display: block;
    object-fit: contain;
    height: 100%;
  }
}

@include media-desktop {
  .block {
    &__itemImgContainer {
      height: 540px;
      min-width: auto;
      overflow: hidden;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

@include media-tablet {
  .block {
    padding: 0px 30px;
    margin: 116px 0;

    &__container {
      gap: 90px;
    }

    &__itemImgContainer {
      width: 456px;
      min-width: 456px;
      height: auto;
    }

    &__itemImg {
      object-fit: contain;
      width: 100%;
      height: auto;
    }

    &__item {
      flex-direction: column;
      gap: 40px;
    }

    &__itemContent {
      max-width: 516px;
    }

    &__itemHeading {
      font-size: 36px;
    }
  }
}

@include media-mobile {
  .block {
    padding: 0px 15px;
    margin: 80px 0;

    &__itemHeading {
      font-size: 24px;
    }

    &__itemImgContainer {
      min-width: 330px;
      width: 330px;
    }
  }
}
