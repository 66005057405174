
          @import "src/assets/styles/variables.module.scss";
          @import "src/assets/styles/media.scss";
        
.footer {
  padding: 40px 30px;
  background-color: $black;

  &__container {
    max-width: $container;
    width: 100%;
    margin: 0 auto;
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-content: space-between;
    gap: 40px;
  }

  &__logoContainer {
    color: $white;
  }

  &__socials {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    margin-right: 30px;
    gap: 40px;
  }

  &__social {
    color: white;
    transition: color 0.3s linear;

    &:hover {
      color: $white;
    }
  }

  &__links {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    align-self: flex-end;
    gap: 20px;
  }

  &__link {
    color: white;
    text-decoration: underline;
    font-size: 14px;
    font-weight: 400;
    transition: color 0.3s linear;

    &:hover {
      color: $white;
    }
  }

  &__copyright {
    color: $secondary_grey;
    font-size: 14px;
    font-weight: 400;
  }
}

@include media-tablet {
  .footer {
    &__container {
      grid-template-columns: 60% 40%;
      column-gap: 0;
    }
  }
}

@include media-mobile {
  .footer {
    padding: 20px 15px;

    &__container {
      grid-template-columns: 1fr;
    }

    &__socials {
      justify-content: flex-start;
    }

    &__links {
      justify-content: flex-start;
      order: 3;
    }

    &__copyright {
      order: 4;
    }
  }
}
