
          @import "src/assets/styles/variables.module.scss";
          @import "src/assets/styles/media.scss";
        
.block {
  width: 100%;
  background-color: $black;
  padding: 80px 150px;

  &__container {
    margin: 0 auto;
  }

  &__title {
    font-size: 64px;
    font-weight: 700;
    line-height: normal;
    text-align: center;
    color: white;
  }

  &__list {
    margin-top: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 90px;
  }

  &__itemContainer {
    height: 50px;

    &:nth-child(1) {
      height: 50px;
    }
    &:nth-child(2) {
      height: 52px;
    }
    &:nth-child(3) {
      height: 36px;
    }
  }

  &__item {
    height: 100%;
    object-fit: contain;
  }
}

@include media-tablet {
  .block {
    padding: 60px 35px;

    &__title {
      font-size: 36px;
    }

    &__list {
      margin-top: 40px;
      gap: 50px;
    }

    &__itemContainer {
      &:nth-child(1) {
        height: 50px;
      }
      &:nth-child(2) {
        height: 52px;
      }
      &:nth-child(3) {
        height: 36px;
      }
    }
  }
}

@include media-mobile {
  .block {
    &__list {
      flex-direction: column;
      gap: 50px;
    }
  }
}
