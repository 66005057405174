
          @import "src/assets/styles/variables.module.scss";
          @import "src/assets/styles/media.scss";
        
.page {
  &__header {
    padding: 22px 30px;
    color: $black;
    height: 80px;
  }

  &__content {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    max-width: $container;
    min-height: calc(91vh - 80px);
    margin: 0 auto;
  }

  &__code {
    font-size: 200px;
    font-weight: 700;
    text-align: center;
    color: $black;
  }

  &__heading {
    font-size: 42px;
    font-weight: 600;
    color: $tertiary_black;
    margin-top: 5px;
  }

  &__text {
    color: $main_grey;
    font-size: 16px;
    font-weight: 500;
    margin-top: 30px;
    margin-bottom: 40px;
    text-align: center;
  }

  &__link {
    color: $black;
  }
}

@include media-tablet {
  .page {
    &__content {
      min-height: calc(93vh - 80px);
    }

    &__text {
      margin-bottom: 45px;
    }

    &__button {
      height: 60px;
      font-size: 18px;
    }

    &__heading {
      margin-top: 0;
    }


  }
}

@include media-mobile {
  .page {
    &__content {
      min-height: calc(90vh - 80px);
      padding: 0 15px;
    }

    &__heading {
      font-size: 32px;
    }

    &__code {
      font-size: 128px;
    }

    &__text {
      margin-top: 20px;
      margin-bottom: 40px;
    }
  }
}
