
          @import "src/assets/styles/variables.module.scss";
          @import "src/assets/styles/media.scss";
        
@keyframes blink {
  from {
    opacity: 0%;
  }
  to {
    opacity: 100%;
  }
}

.block {
  &::after {
    content: '|';
    animation: blink 1s infinite;
  }

  &--end {
    &::after {
      content: '';
      animation: none;
    }
  }
}
