
          @import "src/assets/styles/variables.module.scss";
          @import "src/assets/styles/media.scss";
        
.header {
  height: 80px;
  width: 100%;
  background-color: $black;
  padding: 22px 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid $main_grey;

  &__container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: $container;
    margin: 0 auto;
    width: 100%;
  }

  &__logoContainer {
    color: $white;
    height: 36px;
  }

  &__nav {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 40px;
  }

  &__link {
    font-size: 16px;
    font-weight: 500;
    line-height: normal;
    color: white;
    transition: color 0.2s linear;

    &:hover {
      color: $white;
    }
  }

  &__button {
    height: 48px;
    border: 1px solid white;
    background-color: transparent;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 24px;
    padding: 8px 37px;
    font-size: 16px;
    font-weight: 500;
    line-height: normal;
    min-width: 150px;
    cursor: pointer;
  }

  &__block {
    width: 150px;
  }
}

@include media-tablet {
  .header {
    &__nav {
      display: none;
    }
  }
}

@include media-mobile {
  .header {
    &__container {
      justify-content: center;
    }

    &__block {
      display: none;
    }
  }
}
